import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import links from "../links"
import blogAuthors from "./blog-authors"

const className = "h-48 w-full object-cover"

export default {
  mobileAppRelease: {
    title: "Miten julkaista mobiiliapplikaatio?",
    href: links.blogSites.mobileAppRelease,
    category: "Artikkeli",
    description:
      "Kokosimme yhteen muutamia vinkkejä, joita voit ottaa huomioon julkaistaessa mobiiliapplikaatiota. Kohderyhmän kartoitus Kannattaa luoda kohderyhmä, sillä sen avulla pystyt määrittämään ensinnäkin mille alustalle haluat sovelluksesi julkaistavan. Mitä…",
    date: "helmikuu 22, 2018",
    dateTime: "2018-02-22",
    ImageElement: (
      <StaticImage
        src="../../images/how-release-app.svg"
        alt="Miten julkaista mobiiliapplikaatio?"
        className={className}
      />
    ),
    readingTime: "7 min",
    author: blogAuthors.essi,
  },

  whatApplicationCosts: {
    title: "Mitä mobiilisovelluksen kehittäminen maksaa?",
    href: links.blogSites.whatApplicationCosts,
    category: "Artikkeli",
    description:
      "Nopea Google-haku antaa kymmenittäin sivuja, joissa käydään läpi mobiilisovellusten hintoja, ainakin siis englanniksi. Miksi sitten kirjoittaa samasta aiheesta yhtä lisäkertomusta muiden joukkoon? Käytännössä kaikki löytyvät sivut ovat…",
    date: "helmikuu 26, 2018",
    dateTime: "2018-02-26",
    ImageElement: (
      <StaticImage
        src="../../images/laitteetbg.svg"
        alt="Mitä mobiilisovelluksen kehittäminen maksaa?"
        className={className}
      />
    ),
    readingTime: "9 min",
    author: blogAuthors.jaakko,
  },

  mobileAppBuyerGuide: {
    title: "Mobiilisovelluksen ostajan opas",
    href: links.blogSites.mobileAppBuyerGuide,
    category: "Artikkeli",
    description:
      "Mobiilikäyttäjien määrä saavutti 4.61 miljardia vuonna 2016, ja mobiililaitteilla käytetystä ajasta 86 % kuluu mobiilisovellusten käyttämiseen. Mobiilisovellus on erinomainen tapa kasvattaa liikevaihtoa tai sitouttaa asiakkaita. Tässä kerättynä…",
    date: "helmikuu 12, 2018",
    dateTime: "2018-02-12",
    ImageElement: (
      <StaticImage
        src="../../images/app-buyer-guide.svg"
        alt="Mobiilisovelluksen ostajan opas"
        className={className}
      />
    ),
    readingTime: "4 min",
    author: blogAuthors.henkka,
  },

  fromIdeaToApp: {
    title: "Ideasta mobiilisovellukseksi",
    href: links.blogSites.fromIdeaToApp,
    category: "Artikkeli",
    description:
      "Mobiilisovellukset ovat valtaamassa markkinoita ja mobiilisovelluksen kehittäminen on varmasti käynyt yhä useamman mielessä. Mobiilisovellusten on arvioitu kasvavan yli 6 triljoonan euron markkinaksi vuoteen 2022 mennessä. Siksi onkin…",
    date: "maaliskuu 12, 2018",
    dateTime: "2018-03-12",
    ImageElement: (
      <StaticImage
        src="../../images/from-idea-to-app.svg"
        alt="Ideasta mobiilisovellukseksi"
        className={className}
      />
    ),
    readingTime: "12 min",
    author: blogAuthors.jaakko,
  },

  websiteBuyerGuide: {
    title: "Nettisivujen ostajan opas – nettisivut yritykselle",
    href: links.blogSites.websiteBuyerGuide,
    category: "Artikkeli",
    description:
      "Oletko miettimässä uusien nettisivujen hankkimista tai vanhojen sivujen päivittämistä? Tiedätkö mistä aloittaa ja mitä edes ottaa huomioon? Sinulla saattaa olla mielessä monia kysymyksiä, etkä välttämättä löydä niihin…",
    date: "toukokuu 18, 2018",
    dateTime: "2018-05-18",
    ImageElement: (
      <StaticImage
        src="../../images/website-buyer-guide-cover.svg"
        alt="Nettisivujen ostajan opas – nettisivut yritykselle"
        className={className}
      />
    ),
    readingTime: "11 min",
    author: blogAuthors.essi,
  },
}
