import { StaticImage } from "gatsby-plugin-image"
import React from "react"

const className = "h-10 w-10 rounded-full"

export default {
  essi: {
    name: "Essi Kivivuori",
    ImageElement: (
      <StaticImage
        src="../../images/essi.jpg"
        alt="Essi Kivivuori"
        className={className}
      />
    ),
  },
  jaakko: {
    name: "Jaakko Lamminpää",
    ImageElement: (
      <StaticImage
        src="../../images/jaakko.jpg"
        alt="Jaakko Lamminpää"
        className={className}
      />
    ),
  },
  henkka: {
    name: "Henrik Salmela",
    ImageElement: (
      <StaticImage
        src="../../images/henkka.jpg"
        alt="Henrik Salmela"
        className={className}
      />
    ),
  },
}
