import { useForm } from "@formspree/react"
import React from "react"

export default (): JSX.Element => {
  const [state, handleSubmit] = useForm("xknkregb")

  if (state.succeeded) {
    return (
      <p className="text-white text-lg font-bold uppercase">
        Kiitos, Olemme yhteydessä!
      </p>
    )
  }
  return (
    <form onSubmit={handleSubmit} className="sm:max-w-xl sm:mx-auto lg:mx-0">
      <div className="sm:flex">
        <div className="min-w-0 flex-1">
          <label htmlFor="email" className="sr-only">
            Sähköposti tai puhelinnumero
          </label>
          <input
            id="email"
            type="text"
            name="phoneOrEmail"
            placeholder="Sähköposti tai puhelinnumero"
            className="block w-full px-4 py-3 rounded-md border-0 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-300 focus:ring-offset-gray-900"
          />
        </div>
        <div className="mt-3 sm:mt-0 sm:ml-3">
          <button
            type="submit"
            disabled={state.submitting}
            className="block w-full py-3 px-4 rounded-md shadow bg-indigo-500 text-white font-medium hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-300 focus:ring-offset-gray-900"
          >
            Lähetä
          </button>
        </div>
      </div>
      <p className="mt-3 text-sm text-gray-300 sm:mt-4">
        Jätä sähköpostiosoitteesi tai puhelinnumerosi niin otamme sinuun
        yhteyttä.
      </p>
    </form>
  )
}
