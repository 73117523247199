export default {
  index: "/",
  works: "/toita/",
  blog: "/blogi/",
  blogSites: {
    mobileAppRelease: "/blogi/mobiilisovelluksen-julkaisu/",
    whatApplicationCosts: "/blogi/mita-mobiilisovelluksen-kehittaminen-maksaa/",
    mobileAppBuyerGuide: "/blogi/mobiilisovelluksen-ostajan-opas/",
    fromIdeaToApp: "/blogi/ideasta-mobiilisovellukseksi/",
    websiteBuyerGuide: "/blogi/nettisivujen-ostajan-opas/",
  },
  aboutUs: "/yhteystiedot/",
  innovaatioSeteli: "/innovaatioseteli/",

  services: "/palvelut/",
  mobile: "/mobiilikehitys/",
  software: "/ohjelmistokehitys/",
  webDev: "/web-kehitys/",
  website: "/kotisivut/",
  softwareSolutions: "/ohjelmistokehitys-ratkaisut/",
  iot: "/iot/",
  marketing: "/markkinointi/",
  server: "/palvelimet/",
  uiDesign: "/kayttoliittymasuunnittelu/",
}
