import React from "react"

interface Props {
  children: string | JSX.Element | JSX.Element[]
}

export default ({ children }: Props): JSX.Element => (
  <div className="mt-4 text-3xl tracking-tight font-extrabold text-white sm:mt-5 sm:text-5xl lg:mt-6 xl:text-5xl">
    {children}
  </div>
)
