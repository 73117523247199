import links from "./links"

export default {
  services: [
    {
      name: "Mobiilikehitys",
      link: links.mobile,
    },
    {
      name: "Ohjelmistokehitys",
      link: links.software,
    },
    {
      name: "Web-kehitys",
      link: links.webDev,
    },
    {
      name: "IOT",
      link: links.iot,
    },
    {
      name: "Kotisivut",
      link: links.website,
    },
  ],
  site: [
    {
      name: "Etusivu",
      link: links.index,
    },
    {
      name: "Meistä",
      link: links.aboutUs,
    },
    {
      name: "Töitä",
      link: links.works,
    },
    {
      name: "Blogi",
      link: links.blog,
    },
  ],
  apps: [
    {
      name: "Mainiox",
      link: "/",
    },
    {
      name: "Tuovu",
      link: "/",
    },
  ],
}
