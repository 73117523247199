import { ChevronRightIcon } from "@heroicons/react/solid"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import links from "../content/links"
import ContactForm from "./ContactForm"

interface Props {
  children: JSX.Element | JSX.Element[] | string
  hideContact?: boolean
}

export default ({ children, hideContact }: Props): JSX.Element => {
  return (
    <div className="pt-10 bg-gray-900 sm:pt-16 lg:pt-8 lg:pb-14 lg:overflow-hidden">
      <div className="mx-auto max-w-7xl lg:px-8">
        <div className="lg:grid lg:grid-cols-2 lg:gap-8">
          <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 sm:text-center lg:px-0 lg:text-left lg:flex lg:items-center">
            <div className="lg:py-24">
              <Link
                to={links.works}
                className="inline-flex items-center text-white bg-black rounded-full p-1 pr-2 sm:text-base lg:text-sm xl:text-base hover:text-gray-200"
              >
                <span className="px-3 py-0.5 text-white text-xs font-semibold leading-5 uppercase tracking-wide bg-indigo-500 rounded-full">
                  Töitä
                </span>
                <span className="ml-4 text-sm">
                  Katso mitä olemme tehneet aiemmin
                </span>
                <ChevronRightIcon
                  className="ml-2 w-5 h-5 text-gray-500"
                  aria-hidden="true"
                />
              </Link>
              {children}
              {hideContact ? (
                ""
              ) : (
                <div className="mt-10 sm:mt-12">
                  <ContactForm />
                </div>
              )}
            </div>
          </div>
          <div className="mt-12 lg:m-0 lg:relative">
            <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0">
              <StaticImage
                src="../images/cloud-illustration-indigo-400.svg"
                alt="Profiilikuva pilven päällä"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
