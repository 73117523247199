import links from "./links"

export default [
  { name: "Etusivu", href: links.index },
  { name: "Mobiili", href: links.mobile },
  { name: "Ohjelmistokehitys", href: links.software },
  { name: "Palvelut", href: links.services, isServices: true },
  { name: "Töitä", href: links.works },
  { name: "Blogi", href: links.blog },
  { name: "Meistä", href: links.aboutUs },
]
