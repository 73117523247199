import { Popover, Transition } from "@headlessui/react"
import { ChevronDownIcon, MenuIcon, XIcon } from "@heroicons/react/solid"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React, { Fragment } from "react"
import blogListing from "../content/blog/blog-listing"
import footer from "../content/footer"
import header from "../content/header"
import links from "../content/links"
import ContactForm from "./ContactForm"

interface Props {
  children: JSX.Element | JSX.Element[]
}

const classNames = (...classes: string[]): string => {
  return classes.filter(Boolean).join(" ")
}

const services = [
  {
    name: "Ohjelmistokehitys ratkaisut",
    description: "Monipuolisia ohjelmistokehitys ratkaisuita",
    href: links.softwareSolutions,
  },
  {
    name: "Web-kehitys",
    description:
      "Ohjelmistot jotka ovat suunniteltu toimimaan web ympäristössä.",
    href: links.webDev,
  },
  {
    name: "Kotisivut yritykselle",
    description: "Perinteiset markkinointiin tarkoitetut kotisivut",
    href: links.website,
  },
  {
    name: "Käyttöliittymäsuunnittelu",
    description:
      "Ohjelmistojen ja Appien käyttöliittymä/käyttökokemussuunnittelu",
    href: links.uiDesign,
  },
  {
    name: "IoT",
    description:
      "Esineiden internet, laitteet jotka on suunniteltu toimimaan internetissä",
    href: links.iot,
  },
  {
    name: "Innovaatioseteli",
    description: "Tarvitsetko apua tai oletko hakemassa innovaatioseteliä?",
    href: links.innovaatioSeteli,
  },
]

const desktopLinkClasses =
  "text-base font-medium text-white hover:text-indigo-400"

export default ({ children }: Props): JSX.Element => {
  return (
    <div className="min-h-screen">
      <div className="relative overflow-hidden">
        <Popover className="relative">
          {({ open }): JSX.Element => (
            <>
              <div className="bg-gray-900">
                <nav className="relative max-w-7xl mx-auto">
                  <div className="flex justify-between items-center px-4 py-6 sm:px-6 md:justify-start md:space-x-10">
                    <div>
                      <Link
                        to="/"
                        className="text-white font-bold uppercase text-lg"
                      >
                        Vivecho
                      </Link>
                    </div>
                    <div className="-mr-2 -my-2 md:hidden">
                      <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                        <span className="sr-only">Open menu</span>
                        <MenuIcon className="h-6 w-6" aria-hidden="true" />
                      </Popover.Button>
                    </div>
                    <div className="hidden md:flex-1 md:flex md:items-center md:justify-between">
                      <Popover.Group as="nav" className="flex space-x-10">
                        {header.map(link => {
                          if (link.isServices) {
                            return (
                              <Popover key={link.href} className="relative">
                                {({ open: open2 }): JSX.Element => (
                                  <>
                                    <Popover.Button
                                      className={classNames(
                                        open2
                                          ? "text-indigo-400"
                                          : "text-white",
                                        "group rounded-md inline-flex items-center text-base font-medium hover:text-indigo-400 focus:outline-none"
                                      )}
                                    >
                                      <span>{link.name}</span>
                                      <ChevronDownIcon
                                        className={classNames(
                                          open2
                                            ? "text-gray-600"
                                            : "text-gray-400",
                                          "ml-2 h-5 w-5 group-hover:text-gray-500"
                                        )}
                                        aria-hidden="true"
                                      />
                                    </Popover.Button>

                                    <Transition
                                      show={open2}
                                      as={Fragment}
                                      enter="transition ease-out duration-200"
                                      enterFrom="opacity-0 translate-y-1"
                                      enterTo="opacity-100 translate-y-0"
                                      leave="transition ease-in duration-150"
                                      leaveFrom="opacity-100 translate-y-0"
                                      leaveTo="opacity-0 translate-y-1"
                                    >
                                      <Popover.Panel
                                        static
                                        className="absolute z-10 left-1/2 transform -translate-x-1/2 mt-3 px-2 w-screen max-w-xs sm:px-0"
                                      >
                                        <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                                          <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                                            {services.map(item => (
                                              <Link
                                                key={item.name}
                                                to={item.href}
                                                className="-m-3 p-3 block rounded-md hover:bg-gray-50"
                                              >
                                                <p className="text-base font-medium text-gray-900">
                                                  {item.name}
                                                </p>
                                                <p className="mt-1 text-sm text-gray-500">
                                                  {item.description}
                                                </p>
                                              </Link>
                                            ))}
                                          </div>
                                        </div>
                                      </Popover.Panel>
                                    </Transition>
                                  </>
                                )}
                              </Popover>
                            )
                          }
                          return (
                            <Link
                              to={link.href}
                              key={link.href}
                              className={desktopLinkClasses}
                            >
                              {link.name}
                            </Link>
                          )
                        })}
                      </Popover.Group>
                    </div>
                  </div>

                  <Transition
                    show={open}
                    as={Fragment}
                    enter="duration-200 ease-out"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="duration-100 ease-in"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                  >
                    <Popover.Panel
                      focus
                      static
                      className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
                    >
                      <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
                        <div className="pt-5 pb-6 px-5">
                          <div className="flex items-center justify-between">
                            <div>
                              <StaticImage
                                height={40}
                                className="w-auto"
                                src="../images/logo.png"
                                alt="Vivechon logo"
                              />
                            </div>
                            <div className="-mr-2">
                              <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                                <span className="sr-only">Close menu</span>
                                <XIcon className="h-6 w-6" aria-hidden="true" />
                              </Popover.Button>
                            </div>
                          </div>
                          <div className="mt-6">
                            <nav className="grid gap-6">
                              {header.map(item => (
                                <Link
                                  key={item.name}
                                  to={item.href}
                                  className="-m-3 p-3 flex items-center rounded-lg hover:bg-gray-50"
                                >
                                  <div className="text-base font-medium text-gray-900">
                                    {item.name}
                                  </div>
                                </Link>
                              ))}
                            </nav>
                          </div>
                        </div>
                        <div className="py-6 px-5">
                          <div className="grid grid-cols-2 gap-4">
                            {services.map(item => (
                              <Link
                                key={item.name}
                                to={item.href}
                                className="text-base font-medium text-gray-900 hover:text-gray-700"
                              >
                                {item.name}
                              </Link>
                            ))}
                          </div>
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </nav>
              </div>
            </>
          )}
        </Popover>

        <main>{children}</main>
        <footer className="bg-gray-800" aria-labelledby="footerHeading">
          <p id="footerHeading" className="sr-only">
            Footer
          </p>
          <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
            <div className="xl:grid xl:grid-cols-3 xl:gap-8">
              <div className="grid grid-cols-2 gap-8 xl:col-span-2">
                <div className="md:grid md:grid-cols-2 md:gap-8">
                  <div>
                    <p className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                      Sivusto
                    </p>
                    <ul className="mt-4 space-y-4">
                      {footer.site.map(item => (
                        <li key={item.name}>
                          <Link
                            to={item.link}
                            className="text-base text-gray-300 hover:text-white"
                          >
                            {item.name}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="mt-12 md:mt-0">
                    <p className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                      Palvelut
                    </p>
                    <ul className="mt-4 space-y-4">
                      {footer.services.map(item => (
                        <li key={item.name}>
                          <Link
                            to={item.link}
                            className="text-base text-gray-300 hover:text-white"
                          >
                            {item.name}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div>
                  <p className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                    Blogit
                  </p>
                  <ul className="mt-4 space-y-4">
                    {[
                      blogListing.mobileAppRelease,
                      blogListing.whatApplicationCosts,
                      blogListing.mobileAppBuyerGuide,
                      blogListing.fromIdeaToApp,
                      blogListing.websiteBuyerGuide,
                    ].map(item => (
                      <li key={item.title}>
                        <Link
                          to={item.href}
                          className="text-base text-gray-300 hover:text-white"
                        >
                          {item.title}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="mt-8 xl:mt-0">
                <p className="text-center lg:text-left text-sm font-semibold text-gray-400 tracking-wider uppercase mb-3">
                  Ota yhteyttä
                </p>
                <ContactForm />
                <p className="text-base text-gray-300 mb-3 mt-3">
                  +358 9 4270 4752
                </p>
                <p className="text-base text-gray-300 mb-3">info@vivecho.com</p>
              </div>
            </div>
            <div className="mt-8 border-t border-gray-700 pt-8 md:flex md:items-center md:justify-between">
              <div className="flex space-x-6 md:order-2" />
              <p className="mt-8 text-base text-gray-400 md:mt-0 md:order-1">
                &copy; 2021 Vivecho oy
              </p>
            </div>
          </div>
        </footer>
      </div>
    </div>
  )
}
